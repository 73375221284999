@import "../../../../../../css/basic_def.scss";

.myStickyTop{
    text-align:right;
    position: sticky;
    top: 0px;
    padding: 20px 50px;
    background: #fafafa;
    z-index: 10;
}

.marginTop{
    margin-top: 50px;
}
