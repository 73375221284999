.Container {
  background-color: transparent;
  .headLine {
    flex: 1;
    padding: 10px;
    background-color: #e6e6e6;
    height: 44px;
    h4 {
      color: black;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      font-family: "Source Sans Pro" !important;
    }
  }
} 

.fullBlock {
  visibility: visible;
  position: fixed;
  width: 80%;
  min-height: 200px;
  max-height: 80%;
  top: 100px;
  border-radius: 5px;
  overflow: hidden;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    position: fixed;
    background-color: rgba(0,0,0,0.6);
  }
  .Visible {
    height: calc(100vh - 250px);;
    overflow: auto;
    padding: 10px;
    background-color: white;
  }
  .headLineMinimizeButton {
    background-color: #e6e6e6;
    width: 50px;
    height: 44px;
    text-align: center;
  }
  .buttonStyle {
    margin-top: 7px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 5px;
    min-width: inherit;
    background-color: #2b75bb;
    color: white;
    margin-bottom: 5px;
    border-radius: 50%;
    border: none;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.minBlock {
  position: fixed;
  bottom: 0;
  right: 5%;
  .headLine {
    display: none;
  }
  .buttonStyle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #2b75bb;
    color: white;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    animation-name: fadeInOut;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-width: 3px 3px 0 3px;
    border-style: solid;
    border-color: white;
    box-shadow: 0 0 3px 2px rgba(0,0,0,0.2);
    &:hover {
      border-width: 3px 3px 0 3px;
      border-style: solid;
      border-color: #2b75bb;
    }
  }
}
.Visible {
  visibility: visible;
}

.Invisible {
  display: none;
}
@keyframes fadeInOut {
  0% {background-color: #2b75bb;}
  50% {background-color: #169bd5;}
  100% {background-color: #2b75bb;}
}
