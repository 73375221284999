@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i&display=swap&subset=latin-ext');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

a:hover {
  text-decoration: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr.dividerStyle.MuiDivider-root.MuiDivider-vertical {
  background-color: #e2e2e2;
  height: 25px;
  margin: auto 5px
}

hr.MuiDivider-root.myDividerStyle.MuiDivider-vertical {
  background-color: #e2e2e2;
  height: 125px;
  margin: auto 5px
}


th.MuiTableCell-root.myMuiThStyle {
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-weight: 600
}

td.MuiTableCell-root.myMuiTdStyle {
  font-size: 14px;
  font-family: 'Source Sans Pro';
  font-weight: 600
}


span.MuiTypography-body1 {
  font-size: 14px;
  line-height: 1.1;

}

a {
  text-decoration: none;
  color: inherit;
}


.MuiListItemIconRoot img {
  height: 24px;
}