.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 499;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.Error {
  z-index: 501;
}