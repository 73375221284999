@import "../../../../../../css/basic_def.scss";

.myFontStyle{
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
}

.myTHStyle{
    font-size: 14px;
    font-weight: 600
}