@import "../../../../../css/basic_def.scss";

.myTableHeadStyle {
    background-color: #e6e6e6;

}

.tableHoverStyle {
    background-color: inherit;
    &:hover {
        background-color: rgba(37, 118, 195, 0.1);
    }
}

.paddingStyle{
    padding: 10px !important;
}