.Modal {
  min-width: 240px; /* so save and cancel buttons fit next to eachother */
  max-height: 90%;
  overflow-y: auto;
  position: fixed;
  z-index: 500;
  background-color: white;
  /* width: 100%; */
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  margin: auto;
  overflow-y: auto;
  max-height: 90%;
}

.ModalVisible {
  transform: translateY(0vh);
  opacity: 1;
}

.ModalHidden {
  transform: translateY(-100vh);
  opacity: 0;
}

.Error {
  z-index: 502;
}

.Large {
  top: 5%;
  width: 90%;
  min-height: 75%;
  /* height: 98%; */
}

.Medium {
  width: 60%;
  top: 5%;
  /* height: 98% */
}

.Small {
  width: 20%;
  top: 5%;
  /* height: 25%; */
}