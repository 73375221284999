/*.Column {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}

.LeftContainer,
.RightContainer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.RightContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}*/

button.myBlueButton {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
}
  button.myBlueButton:hover {
      background: #2b75bb;
      opacity: 0.7;
  }

  button.myRedButton {
    background: #990000;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 14px;
    margin-left: 6px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
  }
    button.myRedButton:hover {
        background: #6a0000;
        opacity: 0.7;
    }

  .marginBottom{
    margin-bottom: 15px;
  }

  .fontWeightBold{
    font-weight: 600;
  }

  .myStickyTop{
    text-align:right;
    position: sticky;
    top: 0px;
    padding: 20px 30px;
    background: #fafafa;
    z-index: 10;
}
