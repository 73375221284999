.Navbar {
  /*padding: 0.5rem;*/
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0;
  padding-bottom: 10px;
  /*background: linear-gradient(white, lightgray);*/
  /*box-shadow: 0px 1px 5px 2px grey;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  background: #f7f7f7;
  border-bottom: 1px solid #c6c6c6;
  /* z-index: 1024; */
}

.Nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
}

.DropdownMenu {
 /*background: linear-gradient(white, lightgray);
  box-shadow: 0px 1px 5px 2px grey;
  border-radius: 0px 0px .5rem .5rem;
  color: rgb(128, 128, 128);
  padding: 0.5rem;*/
  border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.24);
    border-radius: 6px;
    background: #fff;
    padding: .5rem;
    margin-top: 10px
}

.DropdownItem {

}

.Right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.NavLink,
.Brand {
  color: rgb(128, 128, 128);
  padding-left: .5rem;
  padding-right: .5rem;
  margin-left: .25rem;
  margin-right: .25rem;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  /*width: 35px;*/
  border-top: 4px solid transparent
}

.Brand {
  font-weight: bold;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-size: 25px
}

.hoverStyle:hover{
  background: rgba(37, 118, 195, 0.1) !important
}

.NavLink:hover{
  opacity: 0.7;
  text-decoration: none;
}

.NavLink:focus {
  /* background-color: aquamarine; */
 /* background: linear-gradient(rgb(235, 235, 235), rgb(175, 175, 175));
  color: rgb(96, 96, 96);
  border-radius: .25rem;*/
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}

.NavLink.active,
.NavLink:active {
  /*background: linear-gradient(lightgray, gray);
  color: white;
  border-radius: .25rem;
  text-decoration: none;*/
  opacity: 1;
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}

.iconText{
  position: relative;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  color: #383838;
  font-size: 14px;
}


.NavLinkActive {
  /*background: linear-gradient(lightgray, gray);
  color: white;
  border-radius: .25rem;
  text-decoration: none;*/
  opacity: 1;
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}