@import "../../../css/basic_def.scss";

.myFontStyle{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.myIconStyle{
    cursor: pointer;
    width: 2.1875rem;
    /*margin-right: 10px;*/
}

.subFont {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }