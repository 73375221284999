.Card {
  margin-top: 1rem;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}

.CardHeader {
  display: flex;
  justify-content: space-between;
  background-color:#e6e6e6;
  border: 1px solid #e6e6e6;
  color: black;
  font-size: 16px;
}

.CardContainer {
  width: 100%;
}

.CardContent{
  min-height: 50px;
  text-align: left;
  padding: 10px;
}

.subCard{
  width: 97%;
  border: 1px solid #f2f2f2;
  margin-left: 2%;
  border-radius: 6px;
}

.subCardContent{
  padding:10px;
}

.subCardHeader{
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: black;
  font-size: 14px;
  padding: 10px ;
  
}

.subCardHeader

.subCardContainer{
  padding: 10px;
  font-size: 14px;
}

.myFontStyle{
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
}

.inputstyle{
  font-size: 14px;
}
.CardHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px
}

.alignSelf{
    display: block;
    text-align: right
}

