/* Színek */
$color-DrkBlue: #2b75bb;
$color-LightBlue: #169bd5;



/* MIXIN'S */

/* BASIC STYLES */

.colHeader {
    font-size: 18px;
    font-weight: 600;
}

.colHeaderRight {
    text-align: right;
    padding-right: 25px;
    @extend .colHeader;

    .linkHeadStyle {
        font-weight: 600;
        font-size: 20px;
        color: $color-DrkBlue;
        text-decoration: none;
    }
}

button.mainBlueButton {
    background: #2b75bb;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 14px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    margin-right: 6px;
    z-index: inherit;
    text-decoration: none;

    &:hover {
        background: #2b75bb;
        opacity: 0.7;
    }

    &WithAlignRight {
        @extend .mainBlueButton;
        position: absolute;
        bottom: 20px;
        right: 50px;
    }
}

button.mainBlueButtonWithTop {
    background: #2b75bb;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 14px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    margin-right: 6px;
    z-index: inherit;
    margin-top: 50px;

    &:hover {
        background: #169bd5;
        opacity: 0.7;
    }

}

.btnT20 {
    margin-top: 20px !important;
}

.alignRight {
    text-align: right;
}

button.myDefaultIcon {
    background: #616161;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 6px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    min-width: 1px;

    &:hover {
        background: #616161;
    }
}

button.myRedIcon {
    background: #990000;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 6px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    min-width: 1px;

    &:hover {
        background: #990000;
        opacity: 0.7;
    }
}

button.myRedButton {
    background: #990000;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 14px;
    margin-left: 6px;
    font-family: 'Source Sans Pro';
    font-weight: 500;

    &:hover {
        background: #990000;
        opacity: 0.7;
    }
}

.cardHeader {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 20px 0;
    text-align: center;
    border-bottom: 1px solid #c6c6c6;

    a {
        font-weight: 400;
        font-size: 16px;
        color: $color-DrkBlue;
        text-decoration: none;
    }
}

.cardBody {
    padding-right: 50px;
    padding-left: 50px;
    z-index: 1;
    padding: 0 50px;
    padding-top: 20px;

    &WithMyStyle {
        @extend .cardBody;
        margin-top: 20px;
    }
}


.Image {
    position: relative;
    max-height: 25px;
    margin-right: .25rem;
    border-radius: 6px
}

img:hover {
    opacity: 0.7;
}

.Icon {
    position: relative;
    max-height: 35px;
    margin-right: .25rem;
    margin-left: 10px;
}

.selectLink {
    color: #2b75bb;

    &:hover {
        cursor: pointer;
    }
}

.inputstyle{
    font-size: 14px;
  }