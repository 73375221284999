@import "../../../css/basic_def.scss";

.myCollectionField{
    display: flex;
    flex-direction: column;
}   

.Header{
    display: flex;
    justify-content: space-between;
}