@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i&display=swap&subset=latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

a:hover {
  text-decoration: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr.dividerStyle.MuiDivider-root.MuiDivider-vertical {
  background-color: #e2e2e2;
  height: 25px;
  margin: auto 5px
}

hr.MuiDivider-root.myDividerStyle.MuiDivider-vertical {
  background-color: #e2e2e2;
  height: 125px;
  margin: auto 5px
}


th.MuiTableCell-root.myMuiThStyle {
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-weight: 600
}

td.MuiTableCell-root.myMuiTdStyle {
  font-size: 14px;
  font-family: 'Source Sans Pro';
  font-weight: 600
}


span.MuiTypography-body1 {
  font-size: 14px;
  line-height: 1.1;

}

a {
  text-decoration: none;
  color: inherit;
}


.MuiListItemIconRoot img {
  height: 24px;
}
.Navigation_Navbar__2jvKv {
  /*padding: 0.5rem;*/
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0;
  padding-bottom: 10px;
  /*background: linear-gradient(white, lightgray);*/
  /*box-shadow: 0px 1px 5px 2px grey;*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  top: 0;
  background: #f7f7f7;
  border-bottom: 1px solid #c6c6c6;
  /* z-index: 1024; */
}

.Navigation_Nav__1qXl3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.Navigation_Image__1bGiy {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px
}

.Navigation_Center__3qOB5 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: .25rem;
}

.Navigation_DropdownMenu__2aaxE {
 /*background: linear-gradient(white, lightgray);
  box-shadow: 0px 1px 5px 2px grey;
  border-radius: 0px 0px .5rem .5rem;
  color: rgb(128, 128, 128);
  padding: 0.5rem;*/
  border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.24);
    border-radius: 6px;
    background: #fff;
    padding: .5rem;
    margin-top: 10px
}

.Navigation_DropdownItem__3IP3C {

}

.Navigation_Right__3ZcAf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.Navigation_NavLink__2ab3I,
.Navigation_Brand__3RBdw {
  color: rgb(128, 128, 128);
  padding-left: .5rem;
  padding-right: .5rem;
  margin-left: .25rem;
  margin-right: .25rem;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  /*width: 35px;*/
  border-top: 4px solid transparent
}

.Navigation_Brand__3RBdw {
  font-weight: bold;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-size: 25px
}

.Navigation_hoverStyle__2q2XT:hover{
  background: rgba(37, 118, 195, 0.1) !important
}

.Navigation_NavLink__2ab3I:hover{
  opacity: 0.7;
  text-decoration: none;
}

.Navigation_NavLink__2ab3I:focus {
  /* background-color: aquamarine; */
 /* background: linear-gradient(rgb(235, 235, 235), rgb(175, 175, 175));
  color: rgb(96, 96, 96);
  border-radius: .25rem;*/
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}

.Navigation_NavLink__2ab3I.Navigation_active__1Sofg,
.Navigation_NavLink__2ab3I:active {
  /*background: linear-gradient(lightgray, gray);
  color: white;
  border-radius: .25rem;
  text-decoration: none;*/
  opacity: 1;
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}

.Navigation_iconText__3kOzH{
  position: relative;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  color: #383838;
  font-size: 14px;
}


.Navigation_NavLinkActive__1CAX- {
  /*background: linear-gradient(lightgray, gray);
  color: white;
  border-radius: .25rem;
  text-decoration: none;*/
  opacity: 1;
  text-decoration: none;
  border-top: 4px solid #2b75bb;
}
.Layout_Navigation__2Qpjs {
  height: 50px;
  position: relative;
  z-index: 3;
}
.Layout_Container__i0CZr {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: auto;
}

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.MetadataProfile_colHeader__3ln7b, .MetadataProfile_colHeaderRight__KdMQG {
  font-size: 18px;
  font-weight: 600; }

.MetadataProfile_colHeaderRight__KdMQG {
  text-align: right;
  padding-right: 25px; }
  .MetadataProfile_colHeaderRight__KdMQG .MetadataProfile_linkHeadStyle__3MA0y {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.MetadataProfile_mainBlueButton__qfo6L, button.MetadataProfile_mainBlueButtonWithAlignRight__1gV4- {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.MetadataProfile_mainBlueButton__qfo6L:hover, button.MetadataProfile_mainBlueButtonWithAlignRight__1gV4-:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.MetadataProfile_mainBlueButtonWithAlignRight__1gV4- {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.MetadataProfile_mainBlueButtonWithTop__6Vgnv {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.MetadataProfile_mainBlueButtonWithTop__6Vgnv:hover {
    background: #169bd5;
    opacity: 0.7; }

.MetadataProfile_btnT20__3OUHI {
  margin-top: 20px !important; }

.MetadataProfile_alignRight__18a1B {
  text-align: right; }

button.MetadataProfile_myDefaultIcon__2kEYY {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.MetadataProfile_myDefaultIcon__2kEYY:hover {
    background: #616161; }

button.MetadataProfile_myRedIcon__3menL {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.MetadataProfile_myRedIcon__3menL:hover {
    background: #990000;
    opacity: 0.7; }

button.MetadataProfile_myRedButton__2JXCh {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.MetadataProfile_myRedButton__2JXCh:hover {
    background: #990000;
    opacity: 0.7; }

.MetadataProfile_cardHeader__uDTLt {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .MetadataProfile_cardHeader__uDTLt a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.MetadataProfile_cardBody__3ENE5, .MetadataProfile_cardBodyWithMyStyle__3LPsW {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .MetadataProfile_cardBodyWithMyStyle__3LPsW {
    margin-top: 20px; }

.MetadataProfile_Image__CObZC {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.MetadataProfile_Icon__1iI92 {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.MetadataProfile_selectLink__2D7Mb {
  color: #2b75bb; }
  .MetadataProfile_selectLink__2D7Mb:hover {
    cursor: pointer; }

.MetadataProfile_inputstyle__nBmTk {
  font-size: 14px; }

.MetadataProfile_myTableHeadStyle__3rLsL {
  background: #e6e6e6; }

.MetadataProfile_tableHoverStyle__1jiRt {
  background-color: inherit; }
  .MetadataProfile_tableHoverStyle__1jiRt:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.MetadataProfile_paddingStyle__DShok {
  padding: 10px !important; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.CollectionListTable_colHeader__-aJql, .CollectionListTable_colHeaderRight__2Mvg_ {
  font-size: 18px;
  font-weight: 600; }

.CollectionListTable_colHeaderRight__2Mvg_ {
  text-align: right;
  padding-right: 25px; }
  .CollectionListTable_colHeaderRight__2Mvg_ .CollectionListTable_linkHeadStyle__vmD-j {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.CollectionListTable_mainBlueButton__3vbqt, button.CollectionListTable_mainBlueButtonWithAlignRight__2Bt_Z {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.CollectionListTable_mainBlueButton__3vbqt:hover, button.CollectionListTable_mainBlueButtonWithAlignRight__2Bt_Z:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.CollectionListTable_mainBlueButtonWithAlignRight__2Bt_Z {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.CollectionListTable_mainBlueButtonWithTop__2nqsy {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.CollectionListTable_mainBlueButtonWithTop__2nqsy:hover {
    background: #169bd5;
    opacity: 0.7; }

.CollectionListTable_btnT20__1Qo8u {
  margin-top: 20px !important; }

.CollectionListTable_alignRight__1Rkxk {
  text-align: right; }

button.CollectionListTable_myDefaultIcon__2yG-o {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionListTable_myDefaultIcon__2yG-o:hover {
    background: #616161; }

button.CollectionListTable_myRedIcon__1WSHQ {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionListTable_myRedIcon__1WSHQ:hover {
    background: #990000;
    opacity: 0.7; }

button.CollectionListTable_myRedButton__2_90_ {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.CollectionListTable_myRedButton__2_90_:hover {
    background: #990000;
    opacity: 0.7; }

.CollectionListTable_cardHeader__3vdld {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .CollectionListTable_cardHeader__3vdld a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.CollectionListTable_cardBody__1A55S, .CollectionListTable_cardBodyWithMyStyle__1k605 {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .CollectionListTable_cardBodyWithMyStyle__1k605 {
    margin-top: 20px; }

.CollectionListTable_Image__28PLx {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.CollectionListTable_Icon__3Mu4E {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.CollectionListTable_selectLink__lOZYs {
  color: #2b75bb; }
  .CollectionListTable_selectLink__lOZYs:hover {
    cursor: pointer; }

.CollectionListTable_inputstyle__CQ_do {
  font-size: 14px; }

.CollectionListTable_myTableHeadStyle__3HBBy {
  background-color: #e6e6e6; }

.CollectionListTable_tableHoverStyle__2aL99 {
  background-color: inherit; }
  .CollectionListTable_tableHoverStyle__2aL99:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.CollectionListTable_paddingStyle__2hbtH {
  padding: 10px !important; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Popup_colHeader__3x_LF, .Popup_colHeaderRight__3OCAl {
  font-size: 18px;
  font-weight: 600; }

.Popup_colHeaderRight__3OCAl {
  text-align: right;
  padding-right: 25px; }
  .Popup_colHeaderRight__3OCAl .Popup_linkHeadStyle__9whZ2 {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Popup_mainBlueButton__7JI50, button.Popup_mainBlueButtonWithAlignRight__SeZ_k {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Popup_mainBlueButton__7JI50:hover, button.Popup_mainBlueButtonWithAlignRight__SeZ_k:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Popup_mainBlueButtonWithAlignRight__SeZ_k {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Popup_mainBlueButtonWithTop__3sGca {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Popup_mainBlueButtonWithTop__3sGca:hover {
    background: #169bd5;
    opacity: 0.7; }

.Popup_btnT20__DSAlW {
  margin-top: 20px !important; }

.Popup_alignRight__23gF8 {
  text-align: right; }

button.Popup_myDefaultIcon__3Kdut {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Popup_myDefaultIcon__3Kdut:hover {
    background: #616161; }

button.Popup_myRedIcon__27m6c {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Popup_myRedIcon__27m6c:hover {
    background: #990000;
    opacity: 0.7; }

button.Popup_myRedButton__HtTpp {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Popup_myRedButton__HtTpp:hover {
    background: #990000;
    opacity: 0.7; }

.Popup_cardHeader__RLzkV {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Popup_cardHeader__RLzkV a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Popup_cardBody__S-i2Z, .Popup_cardBodyWithMyStyle__1JMyK {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Popup_cardBodyWithMyStyle__1JMyK {
    margin-top: 20px; }

.Popup_Image__d6vev {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Popup_Icon__3R2JH {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Popup_selectLink__2q_5C {
  color: #2b75bb; }
  .Popup_selectLink__2q_5C:hover {
    cursor: pointer; }

.Popup_inputstyle__2-nXg {
  font-size: 14px; }

.Popup_myCollectionField__3AyFH {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.Popup_Header__2fsDp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.Modal_Modal__1-5dN {
  min-width: 240px; /* so save and cancel buttons fit next to eachother */
  max-height: 90%;
  overflow-y: auto;
  position: fixed;
  z-index: 500;
  background-color: white;
  /* width: 100%; */
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: auto;
  overflow-y: auto;
  max-height: 90%;
}

.Modal_ModalVisible__XtWNx {
  -webkit-transform: translateY(0vh);
      -ms-transform: translateY(0vh);
          transform: translateY(0vh);
  opacity: 1;
}

.Modal_ModalHidden__N0JWe {
  -webkit-transform: translateY(-100vh);
      -ms-transform: translateY(-100vh);
          transform: translateY(-100vh);
  opacity: 0;
}

.Modal_Error__4PUU2 {
  z-index: 502;
}

.Modal_Large__1Oe2n {
  top: 5%;
  width: 90%;
  min-height: 75%;
  /* height: 98%; */
}

.Modal_Medium__xW5Rt {
  width: 60%;
  top: 5%;
  /* height: 98% */
}

.Modal_Small__QxcEQ {
  width: 20%;
  top: 5%;
  /* height: 25%; */
}
.Backdrop_Backdrop__3j6VK {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 499;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.Backdrop_Error__1MWBV {
  z-index: 501;
}
@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AddCollectionForm_colHeader__1OZdI, .AddCollectionForm_colHeaderRight__1kp0w {
  font-size: 18px;
  font-weight: 600; }

.AddCollectionForm_colHeaderRight__1kp0w {
  text-align: right;
  padding-right: 25px; }
  .AddCollectionForm_colHeaderRight__1kp0w .AddCollectionForm_linkHeadStyle__pM8ZG {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AddCollectionForm_mainBlueButton__wyqxL, button.AddCollectionForm_mainBlueButtonWithAlignRight__1llZj {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AddCollectionForm_mainBlueButton__wyqxL:hover, button.AddCollectionForm_mainBlueButtonWithAlignRight__1llZj:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AddCollectionForm_mainBlueButtonWithAlignRight__1llZj {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AddCollectionForm_mainBlueButtonWithTop__1MQP1 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AddCollectionForm_mainBlueButtonWithTop__1MQP1:hover {
    background: #169bd5;
    opacity: 0.7; }

.AddCollectionForm_btnT20__525Nt {
  margin-top: 20px !important; }

.AddCollectionForm_alignRight__1E4UB {
  text-align: right; }

button.AddCollectionForm_myDefaultIcon__2UpNG {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddCollectionForm_myDefaultIcon__2UpNG:hover {
    background: #616161; }

button.AddCollectionForm_myRedIcon__2YeHk {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddCollectionForm_myRedIcon__2YeHk:hover {
    background: #990000;
    opacity: 0.7; }

button.AddCollectionForm_myRedButton__47X2V {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AddCollectionForm_myRedButton__47X2V:hover {
    background: #990000;
    opacity: 0.7; }

.AddCollectionForm_cardHeader__5wcPE {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AddCollectionForm_cardHeader__5wcPE a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AddCollectionForm_cardBody__fVHAv, .AddCollectionForm_cardBodyWithMyStyle__1w10l {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AddCollectionForm_cardBodyWithMyStyle__1w10l {
    margin-top: 20px; }

.AddCollectionForm_Image__3GgPe {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AddCollectionForm_Icon__c9vKL {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AddCollectionForm_selectLink__3aKgy {
  color: #2b75bb; }
  .AddCollectionForm_selectLink__3aKgy:hover {
    cursor: pointer; }

.AddCollectionForm_inputstyle__xyT9m {
  font-size: 14px; }

input.AddCollectionForm_inputStyle__3T_X- {
  font-size: 14px;
  font-weight: 500; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.CollectionList_colHeader__1dAah, .CollectionList_colHeaderRight__2iUE3 {
  font-size: 18px;
  font-weight: 600; }

.CollectionList_colHeaderRight__2iUE3 {
  text-align: right;
  padding-right: 25px; }
  .CollectionList_colHeaderRight__2iUE3 .CollectionList_linkHeadStyle__3YEuf {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.CollectionList_mainBlueButton__230vd, button.CollectionList_mainBlueButtonWithAlignRight__2Luhv {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.CollectionList_mainBlueButton__230vd:hover, button.CollectionList_mainBlueButtonWithAlignRight__2Luhv:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.CollectionList_mainBlueButtonWithAlignRight__2Luhv {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.CollectionList_mainBlueButtonWithTop__MLx0k {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.CollectionList_mainBlueButtonWithTop__MLx0k:hover {
    background: #169bd5;
    opacity: 0.7; }

.CollectionList_btnT20__1Jie7 {
  margin-top: 20px !important; }

.CollectionList_alignRight__5rGWa {
  text-align: right; }

button.CollectionList_myDefaultIcon__qN-v9 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionList_myDefaultIcon__qN-v9:hover {
    background: #616161; }

button.CollectionList_myRedIcon__1yH21 {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionList_myRedIcon__1yH21:hover {
    background: #990000;
    opacity: 0.7; }

button.CollectionList_myRedButton__2vsu4 {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.CollectionList_myRedButton__2vsu4:hover {
    background: #990000;
    opacity: 0.7; }

.CollectionList_cardHeader__2D75M {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .CollectionList_cardHeader__2D75M a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.CollectionList_cardBody__1fVta, .CollectionList_cardBodyWithMyStyle__2CHmT {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .CollectionList_cardBodyWithMyStyle__2CHmT {
    margin-top: 20px; }

.CollectionList_Image__2lSTU {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.CollectionList_Icon__2ZsYs {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.CollectionList_selectLink__13OFs {
  color: #2b75bb; }
  .CollectionList_selectLink__13OFs:hover {
    cursor: pointer; }

.CollectionList_inputstyle__1f8ST {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.EntityListTable_colHeader__1HdDm, .EntityListTable_colHeaderRight__399Kn {
  font-size: 18px;
  font-weight: 600; }

.EntityListTable_colHeaderRight__399Kn {
  text-align: right;
  padding-right: 25px; }
  .EntityListTable_colHeaderRight__399Kn .EntityListTable_linkHeadStyle__eGTe_ {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.EntityListTable_mainBlueButton__2mBG3, button.EntityListTable_mainBlueButtonWithAlignRight__bo3V0 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.EntityListTable_mainBlueButton__2mBG3:hover, button.EntityListTable_mainBlueButtonWithAlignRight__bo3V0:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.EntityListTable_mainBlueButtonWithAlignRight__bo3V0 {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.EntityListTable_mainBlueButtonWithTop__1utp4 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.EntityListTable_mainBlueButtonWithTop__1utp4:hover {
    background: #169bd5;
    opacity: 0.7; }

.EntityListTable_btnT20__BNHEq {
  margin-top: 20px !important; }

.EntityListTable_alignRight__1PmGd {
  text-align: right; }

button.EntityListTable_myDefaultIcon__--hHI {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityListTable_myDefaultIcon__--hHI:hover {
    background: #616161; }

button.EntityListTable_myRedIcon__tRiYH {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityListTable_myRedIcon__tRiYH:hover {
    background: #990000;
    opacity: 0.7; }

button.EntityListTable_myRedButton__3lpf1 {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.EntityListTable_myRedButton__3lpf1:hover {
    background: #990000;
    opacity: 0.7; }

.EntityListTable_cardHeader__3woA1 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .EntityListTable_cardHeader__3woA1 a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.EntityListTable_cardBody__1DBHn, .EntityListTable_cardBodyWithMyStyle__Yukh_ {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .EntityListTable_cardBodyWithMyStyle__Yukh_ {
    margin-top: 20px; }

.EntityListTable_Image__1Ap2w {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.EntityListTable_Icon__3SDrJ {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.EntityListTable_selectLink__e9BGs {
  color: #2b75bb; }
  .EntityListTable_selectLink__e9BGs:hover {
    cursor: pointer; }

.EntityListTable_inputstyle__1iSpw {
  font-size: 14px; }

.EntityListTable_myTableHeadStyle__2oRa5 {
  background-color: #e6e6e6; }

.EntityListTable_tableHoverStyle__2k-PO {
  background-color: inherit; }
  .EntityListTable_tableHoverStyle__2k-PO:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.EntityListTable_paddingStyle__xyCd6 {
  padding: 10px !important; }

.Spinner_Loader__1twK-,
.Spinner_Loader__1twK-:before,
.Spinner_Loader__1twK-:after {
  background: #2b75bb;
  -webkit-animation: Spinner_load1__1BXDX 1s infinite ease-in-out;
  animation: Spinner_load1__1BXDX 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.Spinner_Loader__1twK- {
  color: #2b75bb;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner_Loader__1twK-:before,
.Spinner_Loader__1twK-:after {
  position: absolute;
  top: 0;
  content: '';
}
.Spinner_Loader__1twK-:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner_Loader__1twK-:after {
  left: 1.5em;
}
.Spinner_Spinner__BWqNs {
  width: 100%;
  height: 5em;
}
@-webkit-keyframes Spinner_load1__1BXDX {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes Spinner_load1__1BXDX {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.Editor_Card__1ggc9 {
  margin-top: 1rem;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}

.Editor_CardHeader__3RTGR {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color:#e6e6e6;
  border: 1px solid #e6e6e6;
  color: black;
  font-size: 16px;
}

.Editor_CardContainer__xtB7_ {
  width: 100%;
}

.Editor_CardContent__1h24v{
  min-height: 50px;
  text-align: left;
  padding: 10px;
}

.Editor_subCard__1eGfD{
  width: 97%;
  border: 1px solid #f2f2f2;
  margin-left: 2%;
  border-radius: 6px;
}

.Editor_subCardContent__3Kj7Y{
  padding:10px;
}

.Editor_subCardHeader__zs3t5{
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: black;
  font-size: 14px;
  padding: 10px ;
  
}

.Editor_subCardHeader__zs3t5

.Editor_subCardContainer___Cno2{
  padding: 10px;
  font-size: 14px;
}

.Editor_myFontStyle__18Gyi{
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
}

.Editor_inputstyle__17Kmj{
  font-size: 14px;
}
.Editor_CardHeader__3RTGR {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 10px
}

.Editor_alignSelf__1EGO2{
    display: block;
    text-align: right
}


@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Controlfield_colHeader__r2dyf, .Controlfield_colHeaderRight__2eITJ {
  font-size: 18px;
  font-weight: 600; }

.Controlfield_colHeaderRight__2eITJ {
  text-align: right;
  padding-right: 25px; }
  .Controlfield_colHeaderRight__2eITJ .Controlfield_linkHeadStyle__38pMS {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Controlfield_mainBlueButton__13i27, button.Controlfield_mainBlueButtonWithAlignRight__2JkXX {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Controlfield_mainBlueButton__13i27:hover, button.Controlfield_mainBlueButtonWithAlignRight__2JkXX:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Controlfield_mainBlueButtonWithAlignRight__2JkXX {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Controlfield_mainBlueButtonWithTop__j-eeV {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Controlfield_mainBlueButtonWithTop__j-eeV:hover {
    background: #169bd5;
    opacity: 0.7; }

.Controlfield_btnT20__2cEDt {
  margin-top: 20px !important; }

.Controlfield_alignRight__2pCSw {
  text-align: right; }

button.Controlfield_myDefaultIcon__EeSMw {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Controlfield_myDefaultIcon__EeSMw:hover {
    background: #616161; }

button.Controlfield_myRedIcon__28XOd {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Controlfield_myRedIcon__28XOd:hover {
    background: #990000;
    opacity: 0.7; }

button.Controlfield_myRedButton__1mtfX {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Controlfield_myRedButton__1mtfX:hover {
    background: #990000;
    opacity: 0.7; }

.Controlfield_cardHeader__1yz6Z {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Controlfield_cardHeader__1yz6Z a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Controlfield_cardBody__3PJ3Q, .Controlfield_cardBodyWithMyStyle__3rG_o {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Controlfield_cardBodyWithMyStyle__3rG_o {
    margin-top: 20px; }

.Controlfield_Image__3XjGm {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Controlfield_Icon__1B6q3 {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Controlfield_selectLink__Ge5JP {
  color: #2b75bb; }
  .Controlfield_selectLink__Ge5JP:hover {
    cursor: pointer; }

.Controlfield_inputstyle__3dXUz {
  font-size: 14px; }

.Controlfield_myFontStyle__3HZDd {
  font-size: 18px;
  font-weight: 600;
  margin: 0; }

.Controlfield_myIconStyle__1kiEV {
  cursor: pointer;
  width: 2.1875rem;
  /*margin-right: 10px;*/ }

.Controlfield_subFont__1PTnX {
  padding: 10px;
  font-size: 14px;
  font-weight: 500; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Datafield_colHeader___Ve-S, .Datafield_colHeaderRight__yl5Sp {
  font-size: 18px;
  font-weight: 600; }

.Datafield_colHeaderRight__yl5Sp {
  text-align: right;
  padding-right: 25px; }
  .Datafield_colHeaderRight__yl5Sp .Datafield_linkHeadStyle__1uQOJ {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Datafield_mainBlueButton__2O7Rz, button.Datafield_mainBlueButtonWithAlignRight__3uIoA {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Datafield_mainBlueButton__2O7Rz:hover, button.Datafield_mainBlueButtonWithAlignRight__3uIoA:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Datafield_mainBlueButtonWithAlignRight__3uIoA {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Datafield_mainBlueButtonWithTop__n_J50 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Datafield_mainBlueButtonWithTop__n_J50:hover {
    background: #169bd5;
    opacity: 0.7; }

.Datafield_btnT20__1EjiX {
  margin-top: 20px !important; }

.Datafield_alignRight__3R2BB {
  text-align: right; }

button.Datafield_myDefaultIcon__L5Bw3 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Datafield_myDefaultIcon__L5Bw3:hover {
    background: #616161; }

button.Datafield_myRedIcon__3yFHb {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Datafield_myRedIcon__3yFHb:hover {
    background: #990000;
    opacity: 0.7; }

button.Datafield_myRedButton__1AukP {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Datafield_myRedButton__1AukP:hover {
    background: #990000;
    opacity: 0.7; }

.Datafield_cardHeader__3FSK- {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Datafield_cardHeader__3FSK- a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Datafield_cardBody__1sJT0, .Datafield_cardBodyWithMyStyle__4UgpX {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Datafield_cardBodyWithMyStyle__4UgpX {
    margin-top: 20px; }

.Datafield_Image__3MArJ {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Datafield_Icon__2xOiN {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Datafield_selectLink__1KBOu {
  color: #2b75bb; }
  .Datafield_selectLink__1KBOu:hover {
    cursor: pointer; }

.Datafield_inputstyle__IS7ey {
  font-size: 14px; }

.Datafield_Datafields__3h8nr {
  margin-bottom: 10vh; }

.Datafield_myFontStyle__1dxj8 {
  font-size: 18px;
  font-weight: 600;
  margin: 0; }

.Datafield_myIconStyle__1to58 {
  cursor: pointer;
  width: 2.1875rem; }

.Datafield_subFont__2AIWE {
  padding: 10px;
  font-size: 14px;
  font-weight: 500; }

.Datafield_aligSelf__SS5AP {
  text-align: right; }

.Datafield_inputstyle__IS7ey {
  font-size: 14px; }

.Datafield_InvalidValue__ffA6z {
  border: 1px solid red; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.List_colHeader__1MfRg, .List_colHeaderRight__1f2P_ {
  font-size: 18px;
  font-weight: 600; }

.List_colHeaderRight__1f2P_ {
  text-align: right;
  padding-right: 25px; }
  .List_colHeaderRight__1f2P_ .List_linkHeadStyle__2ol1m {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.List_mainBlueButton__3VREP, button.List_mainBlueButtonWithAlignRight__2itdT {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.List_mainBlueButton__3VREP:hover, button.List_mainBlueButtonWithAlignRight__2itdT:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.List_mainBlueButtonWithAlignRight__2itdT {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.List_mainBlueButtonWithTop__C1-Ot {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.List_mainBlueButtonWithTop__C1-Ot:hover {
    background: #169bd5;
    opacity: 0.7; }

.List_btnT20__3G1Vm {
  margin-top: 20px !important; }

.List_alignRight__2WS4H {
  text-align: right; }

button.List_myDefaultIcon__1X0HD {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myDefaultIcon__1X0HD:hover {
    background: #616161; }

button.List_myRedIcon__3IjAk {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myRedIcon__3IjAk:hover {
    background: #990000;
    opacity: 0.7; }

button.List_myRedButton__1RU8N {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.List_myRedButton__1RU8N:hover {
    background: #990000;
    opacity: 0.7; }

.List_cardHeader__P1G9U {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .List_cardHeader__P1G9U a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.List_cardBody__20A1y, .List_cardBodyWithMyStyle__37O4q {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .List_cardBodyWithMyStyle__37O4q {
    margin-top: 20px; }

.List_Image__tIgN3 {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.List_Icon__iNbl0 {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.List_selectLink__iKqQI {
  color: #2b75bb; }
  .List_selectLink__iKqQI:hover {
    cursor: pointer; }

.List_inputstyle__3-dXD {
  font-size: 14px; }

.List_alignStyle__AJ9On {
  -ms-flex-pack: end;
      justify-content: flex-end;
  display: -ms-flexbox;
  display: flex; }

.List_myFontStyle__1SJ7J {
  font-size: 18px;
  font-weight: 600;
  margin: 0; }

.List_mySubfontH3__21_L2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0; }

.List_mySubfontH4__2gyXK {
  font-size: 14px;
  font-weight: 600;
  margin: 0; }

.List_myIconStyle__1FniH {
  cursor: pointer;
  width: 3rem; }

.DocumentFormList_Container__2iWWb {
  background-color: transparent; }
  .DocumentFormList_Container__2iWWb .DocumentFormList_headLine__3giET {
    -ms-flex: 1 1;
        flex: 1 1;
    padding: 10px;
    background-color: #e6e6e6;
    height: 44px; }
    .DocumentFormList_Container__2iWWb .DocumentFormList_headLine__3giET h4 {
      color: black;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      font-family: "Source Sans Pro" !important; }

.DocumentFormList_fullBlock__VQn3t {
  visibility: visible;
  position: fixed;
  width: 80%;
  min-height: 200px;
  max-height: 80%;
  top: 100px;
  border-radius: 5px;
  overflow: hidden; }
  .DocumentFormList_fullBlock__VQn3t:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6); }
  .DocumentFormList_fullBlock__VQn3t .DocumentFormList_Visible__1DHiC {
    height: calc(100vh - 250px);
    overflow: auto;
    padding: 10px;
    background-color: white; }
  .DocumentFormList_fullBlock__VQn3t .DocumentFormList_headLineMinimizeButton__Q82PQ {
    background-color: #e6e6e6;
    width: 50px;
    height: 44px;
    text-align: center; }
  .DocumentFormList_fullBlock__VQn3t .DocumentFormList_buttonStyle__1Kmxg {
    margin-top: 7px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 5px;
    min-width: inherit;
    background-color: #2b75bb;
    color: white;
    margin-bottom: 5px;
    border-radius: 50%;
    border: none; }
    .DocumentFormList_fullBlock__VQn3t .DocumentFormList_buttonStyle__1Kmxg svg {
      width: 20px;
      height: 20px; }

.DocumentFormList_minBlock__u3h8J {
  position: fixed;
  bottom: 0;
  right: 5%; }
  .DocumentFormList_minBlock__u3h8J .DocumentFormList_headLine__3giET {
    display: none; }
  .DocumentFormList_minBlock__u3h8J .DocumentFormList_buttonStyle__1Kmxg {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #2b75bb;
    color: white;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-animation-name: DocumentFormList_fadeInOut__1-iR2;
            animation-name: DocumentFormList_fadeInOut__1-iR2;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-width: 3px 3px 0 3px;
    border-style: solid;
    border-color: white;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2); }
    .DocumentFormList_minBlock__u3h8J .DocumentFormList_buttonStyle__1Kmxg:hover {
      border-width: 3px 3px 0 3px;
      border-style: solid;
      border-color: #2b75bb; }

.DocumentFormList_Visible__1DHiC {
  visibility: visible; }

.DocumentFormList_Invisible__3aUHW {
  display: none; }

@-webkit-keyframes DocumentFormList_fadeInOut__1-iR2 {
  0% {
    background-color: #2b75bb; }
  50% {
    background-color: #169bd5; }
  100% {
    background-color: #2b75bb; } }

@keyframes DocumentFormList_fadeInOut__1-iR2 {
  0% {
    background-color: #2b75bb; }
  50% {
    background-color: #169bd5; }
  100% {
    background-color: #2b75bb; } }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AuthorityGeo_colHeader__TGEhb, .AuthorityGeo_colHeaderRight__2_YJS {
  font-size: 18px;
  font-weight: 600; }

.AuthorityGeo_colHeaderRight__2_YJS {
  text-align: right;
  padding-right: 25px; }
  .AuthorityGeo_colHeaderRight__2_YJS .AuthorityGeo_linkHeadStyle__3jmWY {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AuthorityGeo_mainBlueButton__2d2GX, button.AuthorityGeo_mainBlueButtonWithAlignRight__1kffO {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AuthorityGeo_mainBlueButton__2d2GX:hover, button.AuthorityGeo_mainBlueButtonWithAlignRight__1kffO:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AuthorityGeo_mainBlueButtonWithAlignRight__1kffO {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AuthorityGeo_mainBlueButtonWithTop__1LOcJ {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AuthorityGeo_mainBlueButtonWithTop__1LOcJ:hover {
    background: #169bd5;
    opacity: 0.7; }

.AuthorityGeo_btnT20__2-yH9 {
  margin-top: 20px !important; }

.AuthorityGeo_alignRight__1NOak {
  text-align: right; }

button.AuthorityGeo_myDefaultIcon__17FnC {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityGeo_myDefaultIcon__17FnC:hover {
    background: #616161; }

button.AuthorityGeo_myRedIcon__1aiL- {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityGeo_myRedIcon__1aiL-:hover {
    background: #990000;
    opacity: 0.7; }

button.AuthorityGeo_myRedButton__28ZsB {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AuthorityGeo_myRedButton__28ZsB:hover {
    background: #990000;
    opacity: 0.7; }

.AuthorityGeo_cardHeader__YrpHe {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AuthorityGeo_cardHeader__YrpHe a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AuthorityGeo_cardBody__-anjW, .AuthorityGeo_cardBodyWithMyStyle__1scpv {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AuthorityGeo_cardBodyWithMyStyle__1scpv {
    margin-top: 20px; }

.AuthorityGeo_Image__24YPD {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AuthorityGeo_Icon__3o95H {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AuthorityGeo_selectLink__2Riw6 {
  color: #2b75bb; }
  .AuthorityGeo_selectLink__2Riw6:hover {
    cursor: pointer; }

.AuthorityGeo_inputstyle__2mjx3 {
  font-size: 14px; }

.AuthorityGeo_myFontStyle__1D0Ob {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0; }

.AuthorityGeo_myTHStyle__2mCTk {
  font-size: 14px;
  font-weight: 600; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AuthorityPerson_colHeader__3YDyj, .AuthorityPerson_colHeaderRight__1M2m8 {
  font-size: 18px;
  font-weight: 600; }

.AuthorityPerson_colHeaderRight__1M2m8 {
  text-align: right;
  padding-right: 25px; }
  .AuthorityPerson_colHeaderRight__1M2m8 .AuthorityPerson_linkHeadStyle__3HSMC {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AuthorityPerson_mainBlueButton__Bf-iS, button.AuthorityPerson_mainBlueButtonWithAlignRight__3oTvz {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AuthorityPerson_mainBlueButton__Bf-iS:hover, button.AuthorityPerson_mainBlueButtonWithAlignRight__3oTvz:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AuthorityPerson_mainBlueButtonWithAlignRight__3oTvz {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AuthorityPerson_mainBlueButtonWithTop__3I8Z9 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AuthorityPerson_mainBlueButtonWithTop__3I8Z9:hover {
    background: #169bd5;
    opacity: 0.7; }

.AuthorityPerson_btnT20__18DLF {
  margin-top: 20px !important; }

.AuthorityPerson_alignRight__2IjrT {
  text-align: right; }

button.AuthorityPerson_myDefaultIcon__2ebSq {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityPerson_myDefaultIcon__2ebSq:hover {
    background: #616161; }

button.AuthorityPerson_myRedIcon__3VlpX {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityPerson_myRedIcon__3VlpX:hover {
    background: #990000;
    opacity: 0.7; }

button.AuthorityPerson_myRedButton__1-r0h {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AuthorityPerson_myRedButton__1-r0h:hover {
    background: #990000;
    opacity: 0.7; }

.AuthorityPerson_cardHeader__2nMY4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AuthorityPerson_cardHeader__2nMY4 a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AuthorityPerson_cardBody__1cxoZ, .AuthorityPerson_cardBodyWithMyStyle__PbJzq {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AuthorityPerson_cardBodyWithMyStyle__PbJzq {
    margin-top: 20px; }

.AuthorityPerson_Image__QYsGE {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AuthorityPerson_Icon__2A6Of {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AuthorityPerson_selectLink__1w6yE {
  color: #2b75bb; }
  .AuthorityPerson_selectLink__1w6yE:hover {
    cursor: pointer; }

.AuthorityPerson_inputstyle__3O9aM {
  font-size: 14px; }

.AuthorityPerson_myFontStyle__1J-DB {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0; }

.AuthorityPerson_myTHStyle__hDKms {
  font-size: 14px;
  font-weight: 600; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AddEntityForm_colHeader__1529q, .AddEntityForm_colHeaderRight__10QkR {
  font-size: 18px;
  font-weight: 600; }

.AddEntityForm_colHeaderRight__10QkR {
  text-align: right;
  padding-right: 25px; }
  .AddEntityForm_colHeaderRight__10QkR .AddEntityForm_linkHeadStyle__1LDBr {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AddEntityForm_mainBlueButton__3r0wh, button.AddEntityForm_mainBlueButtonWithAlignRight__2DHWQ {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AddEntityForm_mainBlueButton__3r0wh:hover, button.AddEntityForm_mainBlueButtonWithAlignRight__2DHWQ:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AddEntityForm_mainBlueButtonWithAlignRight__2DHWQ {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AddEntityForm_mainBlueButtonWithTop__nw4iD {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AddEntityForm_mainBlueButtonWithTop__nw4iD:hover {
    background: #169bd5;
    opacity: 0.7; }

.AddEntityForm_btnT20__2lTdW {
  margin-top: 20px !important; }

.AddEntityForm_alignRight__1q-pY {
  text-align: right; }

button.AddEntityForm_myDefaultIcon__3soCV {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddEntityForm_myDefaultIcon__3soCV:hover {
    background: #616161; }

button.AddEntityForm_myRedIcon__32HQE {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddEntityForm_myRedIcon__32HQE:hover {
    background: #990000;
    opacity: 0.7; }

button.AddEntityForm_myRedButton__3hEkP {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AddEntityForm_myRedButton__3hEkP:hover {
    background: #990000;
    opacity: 0.7; }

.AddEntityForm_cardHeader__1T1t9 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AddEntityForm_cardHeader__1T1t9 a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AddEntityForm_cardBody__2RVyB, .AddEntityForm_cardBodyWithMyStyle__2TYDj {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AddEntityForm_cardBodyWithMyStyle__2TYDj {
    margin-top: 20px; }

.AddEntityForm_Image__2BxPb {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AddEntityForm_Icon__1Q8fK {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AddEntityForm_selectLink__1bD0X {
  color: #2b75bb; }
  .AddEntityForm_selectLink__1bD0X:hover {
    cursor: pointer; }

.AddEntityForm_inputstyle__CZj5p {
  font-size: 14px; }

.AddEntityForm_alignRight__1q-pY {
  text-align: right; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.EntityList_colHeader__wNYxq, .EntityList_colHeaderRight__OOrXl {
  font-size: 18px;
  font-weight: 600; }

.EntityList_colHeaderRight__OOrXl {
  text-align: right;
  padding-right: 25px; }
  .EntityList_colHeaderRight__OOrXl .EntityList_linkHeadStyle__2wlJ3 {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.EntityList_mainBlueButton__1ytdc, button.EntityList_mainBlueButtonWithAlignRight__2eOXN {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.EntityList_mainBlueButton__1ytdc:hover, button.EntityList_mainBlueButtonWithAlignRight__2eOXN:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.EntityList_mainBlueButtonWithAlignRight__2eOXN {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.EntityList_mainBlueButtonWithTop__36pyL {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.EntityList_mainBlueButtonWithTop__36pyL:hover {
    background: #169bd5;
    opacity: 0.7; }

.EntityList_btnT20__1VUYT {
  margin-top: 20px !important; }

.EntityList_alignRight__rz_3Q {
  text-align: right; }

button.EntityList_myDefaultIcon__3aTIf {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityList_myDefaultIcon__3aTIf:hover {
    background: #616161; }

button.EntityList_myRedIcon__Y68Zt {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityList_myRedIcon__Y68Zt:hover {
    background: #990000;
    opacity: 0.7; }

button.EntityList_myRedButton__1DAwi {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.EntityList_myRedButton__1DAwi:hover {
    background: #990000;
    opacity: 0.7; }

.EntityList_cardHeader__3IVEa {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .EntityList_cardHeader__3IVEa a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.EntityList_cardBody__1nKt6, .EntityList_cardBodyWithMyStyle__1vSui {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .EntityList_cardBodyWithMyStyle__1vSui {
    margin-top: 20px; }

.EntityList_Image__2rXY9 {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.EntityList_Icon__-DGpI {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.EntityList_selectLink__2OmV2 {
  color: #2b75bb; }
  .EntityList_selectLink__2OmV2:hover {
    cursor: pointer; }

.EntityList_inputstyle__32O45 {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AuthorityCorporate_colHeader__3AH2q, .AuthorityCorporate_colHeaderRight__2yhib {
  font-size: 18px;
  font-weight: 600; }

.AuthorityCorporate_colHeaderRight__2yhib {
  text-align: right;
  padding-right: 25px; }
  .AuthorityCorporate_colHeaderRight__2yhib .AuthorityCorporate_linkHeadStyle__KptIu {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AuthorityCorporate_mainBlueButton__27k0a, button.AuthorityCorporate_mainBlueButtonWithAlignRight__Ut4p3 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AuthorityCorporate_mainBlueButton__27k0a:hover, button.AuthorityCorporate_mainBlueButtonWithAlignRight__Ut4p3:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AuthorityCorporate_mainBlueButtonWithAlignRight__Ut4p3 {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AuthorityCorporate_mainBlueButtonWithTop__3Av3M {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AuthorityCorporate_mainBlueButtonWithTop__3Av3M:hover {
    background: #169bd5;
    opacity: 0.7; }

.AuthorityCorporate_btnT20__J82YM {
  margin-top: 20px !important; }

.AuthorityCorporate_alignRight__3YwgZ {
  text-align: right; }

button.AuthorityCorporate_myDefaultIcon__UfDvm {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityCorporate_myDefaultIcon__UfDvm:hover {
    background: #616161; }

button.AuthorityCorporate_myRedIcon__15tWR {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AuthorityCorporate_myRedIcon__15tWR:hover {
    background: #990000;
    opacity: 0.7; }

button.AuthorityCorporate_myRedButton__1SDTH {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AuthorityCorporate_myRedButton__1SDTH:hover {
    background: #990000;
    opacity: 0.7; }

.AuthorityCorporate_cardHeader__2hood {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AuthorityCorporate_cardHeader__2hood a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AuthorityCorporate_cardBody__2jqpf, .AuthorityCorporate_cardBodyWithMyStyle__2Tnh- {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AuthorityCorporate_cardBodyWithMyStyle__2Tnh- {
    margin-top: 20px; }

.AuthorityCorporate_Image__1z4TG {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AuthorityCorporate_Icon__1JiIg {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AuthorityCorporate_selectLink__2ez_v {
  color: #2b75bb; }
  .AuthorityCorporate_selectLink__2ez_v:hover {
    cursor: pointer; }

.AuthorityCorporate_inputstyle__VT6k2 {
  font-size: 14px; }

.AuthorityCorporate_myFontStyle__3Z6ia {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0; }

.AuthorityCorporate_myTHStyle__3Y0xj {
  font-size: 14px;
  font-weight: 600; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Selector_colHeader__zy-nH, .Selector_colHeaderRight__2MXjm {
  font-size: 18px;
  font-weight: 600; }

.Selector_colHeaderRight__2MXjm {
  text-align: right;
  padding-right: 25px; }
  .Selector_colHeaderRight__2MXjm .Selector_linkHeadStyle__1SByH {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Selector_mainBlueButton__3REPV, button.Selector_mainBlueButtonWithAlignRight__2pEoS {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Selector_mainBlueButton__3REPV:hover, button.Selector_mainBlueButtonWithAlignRight__2pEoS:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Selector_mainBlueButtonWithAlignRight__2pEoS {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Selector_mainBlueButtonWithTop__2OeKN {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Selector_mainBlueButtonWithTop__2OeKN:hover {
    background: #169bd5;
    opacity: 0.7; }

.Selector_btnT20__3AGs3 {
  margin-top: 20px !important; }

.Selector_alignRight__2lFwH {
  text-align: right; }

button.Selector_myDefaultIcon__13x42 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Selector_myDefaultIcon__13x42:hover {
    background: #616161; }

button.Selector_myRedIcon__2Eusu {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Selector_myRedIcon__2Eusu:hover {
    background: #990000;
    opacity: 0.7; }

button.Selector_myRedButton__2HhUZ {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Selector_myRedButton__2HhUZ:hover {
    background: #990000;
    opacity: 0.7; }

.Selector_cardHeader__25eJh {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Selector_cardHeader__25eJh a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Selector_cardBody__xcW_d, .Selector_cardBodyWithMyStyle__3o0sc {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Selector_cardBodyWithMyStyle__3o0sc {
    margin-top: 20px; }

.Selector_Image__37wnv {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Selector_Icon__2Oqhb {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Selector_selectLink__1-QxJ {
  color: #2b75bb; }
  .Selector_selectLink__1-QxJ:hover {
    cursor: pointer; }

.Selector_inputstyle__2n6hO {
  font-size: 14px; }

.Selector_myBorderStyle__1D3Y1 {
  border-right: 1px solid rgba(0, 0, 0, 0.125); }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.CollectionShow_colHeader__1iaww, .CollectionShow_colHeaderRight__2Z9oc {
  font-size: 18px;
  font-weight: 600; }

.CollectionShow_colHeaderRight__2Z9oc {
  text-align: right;
  padding-right: 25px; }
  .CollectionShow_colHeaderRight__2Z9oc .CollectionShow_linkHeadStyle__24-vQ {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.CollectionShow_mainBlueButton__2kaak, button.CollectionShow_mainBlueButtonWithAlignRight__1Qy4M {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.CollectionShow_mainBlueButton__2kaak:hover, button.CollectionShow_mainBlueButtonWithAlignRight__1Qy4M:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.CollectionShow_mainBlueButtonWithAlignRight__1Qy4M {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.CollectionShow_mainBlueButtonWithTop__2Bt-C {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.CollectionShow_mainBlueButtonWithTop__2Bt-C:hover {
    background: #169bd5;
    opacity: 0.7; }

.CollectionShow_btnT20__ES-cE {
  margin-top: 20px !important; }

.CollectionShow_alignRight__3vLx8 {
  text-align: right; }

button.CollectionShow_myDefaultIcon__11f48 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionShow_myDefaultIcon__11f48:hover {
    background: #616161; }

button.CollectionShow_myRedIcon__2MzyJ {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.CollectionShow_myRedIcon__2MzyJ:hover {
    background: #990000;
    opacity: 0.7; }

button.CollectionShow_myRedButton__2OUDs {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.CollectionShow_myRedButton__2OUDs:hover {
    background: #990000;
    opacity: 0.7; }

.CollectionShow_cardHeader__2sOgp {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .CollectionShow_cardHeader__2sOgp a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.CollectionShow_cardBody__---NP, .CollectionShow_cardBodyWithMyStyle__hN5dO {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .CollectionShow_cardBodyWithMyStyle__hN5dO {
    margin-top: 20px; }

.CollectionShow_Image__3Buap {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.CollectionShow_Icon__1u5I2 {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.CollectionShow_selectLink__2dgav {
  color: #2b75bb; }
  .CollectionShow_selectLink__2dgav:hover {
    cursor: pointer; }

.CollectionShow_inputstyle__I2D0K {
  font-size: 14px; }

.CollectionShow_myTableHeadStyle__33zXo {
  background-color: #e6e6e6; }

.CollectionShow_tableHoverStyle__328lH {
  background-color: inherit; }
  .CollectionShow_tableHoverStyle__328lH:hover {
    background-color: rgba(37, 118, 195, 0.1); }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.EntityShow_colHeader__36ApT, .EntityShow_colHeaderRight__1y66E {
  font-size: 18px;
  font-weight: 600; }

.EntityShow_colHeaderRight__1y66E {
  text-align: right;
  padding-right: 25px; }
  .EntityShow_colHeaderRight__1y66E .EntityShow_linkHeadStyle__2iNaA {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.EntityShow_mainBlueButton__NRKzh, button.EntityShow_mainBlueButtonWithAlignRight__3lV0d {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.EntityShow_mainBlueButton__NRKzh:hover, button.EntityShow_mainBlueButtonWithAlignRight__3lV0d:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.EntityShow_mainBlueButtonWithAlignRight__3lV0d {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.EntityShow_mainBlueButtonWithTop__3tqDv {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.EntityShow_mainBlueButtonWithTop__3tqDv:hover {
    background: #169bd5;
    opacity: 0.7; }

.EntityShow_btnT20__2lgge {
  margin-top: 20px !important; }

.EntityShow_alignRight__2fR1w {
  text-align: right; }

button.EntityShow_myDefaultIcon__2nTvg {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityShow_myDefaultIcon__2nTvg:hover {
    background: #616161; }

button.EntityShow_myRedIcon__lwQsS {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.EntityShow_myRedIcon__lwQsS:hover {
    background: #990000;
    opacity: 0.7; }

button.EntityShow_myRedButton__3hIrN {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.EntityShow_myRedButton__3hIrN:hover {
    background: #990000;
    opacity: 0.7; }

.EntityShow_cardHeader__3ToL9 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .EntityShow_cardHeader__3ToL9 a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.EntityShow_cardBody__1-0ne, .EntityShow_cardBodyWithMyStyle__24C-A {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .EntityShow_cardBodyWithMyStyle__24C-A {
    margin-top: 20px; }

.EntityShow_Image__aD5gP {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.EntityShow_Icon__3QdBy {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.EntityShow_selectLink__3xfT- {
  color: #2b75bb; }
  .EntityShow_selectLink__3xfT-:hover {
    cursor: pointer; }

.EntityShow_inputstyle__279pV {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Numbers_colHeader__2-0Vv, .Numbers_colHeaderRight__1HuJS {
  font-size: 18px;
  font-weight: 600; }

.Numbers_colHeaderRight__1HuJS {
  text-align: right;
  padding-right: 25px; }
  .Numbers_colHeaderRight__1HuJS .Numbers_linkHeadStyle__3pZo- {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Numbers_mainBlueButton__2Yc4o, button.Numbers_mainBlueButtonWithAlignRight__VoDXD {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Numbers_mainBlueButton__2Yc4o:hover, button.Numbers_mainBlueButtonWithAlignRight__VoDXD:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Numbers_mainBlueButtonWithAlignRight__VoDXD {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Numbers_mainBlueButtonWithTop__3430O {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Numbers_mainBlueButtonWithTop__3430O:hover {
    background: #169bd5;
    opacity: 0.7; }

.Numbers_btnT20__2kWTU {
  margin-top: 20px !important; }

.Numbers_alignRight__3ytKK {
  text-align: right; }

button.Numbers_myDefaultIcon__2ib5E {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Numbers_myDefaultIcon__2ib5E:hover {
    background: #616161; }

button.Numbers_myRedIcon__yEaO4 {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Numbers_myRedIcon__yEaO4:hover {
    background: #990000;
    opacity: 0.7; }

button.Numbers_myRedButton__3QaQc {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Numbers_myRedButton__3QaQc:hover {
    background: #990000;
    opacity: 0.7; }

.Numbers_cardHeader__3Y-Eu {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Numbers_cardHeader__3Y-Eu a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Numbers_cardBody__1liXT, .Numbers_cardBodyWithMyStyle__34ndy {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Numbers_cardBodyWithMyStyle__34ndy {
    margin-top: 20px; }

.Numbers_Image__1ecZf {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Numbers_Icon__3g9Hy {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Numbers_selectLink__3m4fn {
  color: #2b75bb; }
  .Numbers_selectLink__3m4fn:hover {
    cursor: pointer; }

.Numbers_inputstyle__1FaLW {
  font-size: 14px; }

.Numbers_marginRight__1jKh3 {
  margin-right: 5px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Marc21Callsign_colHeader__2Gipr, .Marc21Callsign_colHeaderRight__3o38T {
  font-size: 18px;
  font-weight: 600; }

.Marc21Callsign_colHeaderRight__3o38T {
  text-align: right;
  padding-right: 25px; }
  .Marc21Callsign_colHeaderRight__3o38T .Marc21Callsign_linkHeadStyle__3byDo {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Marc21Callsign_mainBlueButton__1DdNs, button.Marc21Callsign_mainBlueButtonWithAlignRight__7BVkq {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Marc21Callsign_mainBlueButton__1DdNs:hover, button.Marc21Callsign_mainBlueButtonWithAlignRight__7BVkq:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Marc21Callsign_mainBlueButtonWithAlignRight__7BVkq {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Marc21Callsign_mainBlueButtonWithTop__1P-a- {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Marc21Callsign_mainBlueButtonWithTop__1P-a-:hover {
    background: #169bd5;
    opacity: 0.7; }

.Marc21Callsign_btnT20__1gQFp {
  margin-top: 20px !important; }

.Marc21Callsign_alignRight__1MHld {
  text-align: right; }

button.Marc21Callsign_myDefaultIcon__2Y58K {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Marc21Callsign_myDefaultIcon__2Y58K:hover {
    background: #616161; }

button.Marc21Callsign_myRedIcon__2s3WQ {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Marc21Callsign_myRedIcon__2s3WQ:hover {
    background: #990000;
    opacity: 0.7; }

button.Marc21Callsign_myRedButton__1MQlP {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Marc21Callsign_myRedButton__1MQlP:hover {
    background: #990000;
    opacity: 0.7; }

.Marc21Callsign_cardHeader__ophTV {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Marc21Callsign_cardHeader__ophTV a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Marc21Callsign_cardBody__3JrSE, .Marc21Callsign_cardBodyWithMyStyle__2Qiya {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Marc21Callsign_cardBodyWithMyStyle__2Qiya {
    margin-top: 20px; }

.Marc21Callsign_Image__2hqKp {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Marc21Callsign_Icon__2Z7IP {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Marc21Callsign_selectLink__g_hsQ {
  color: #2b75bb; }
  .Marc21Callsign_selectLink__g_hsQ:hover {
    cursor: pointer; }

.Marc21Callsign_inputstyle__2MCDo {
  font-size: 14px; }

.Marc21Callsign_myStickyTop__5iSKZ {
  text-align: right;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 20px 50px;
  background: #fafafa;
  z-index: 10; }

.Marc21Callsign_marginTop___BhLx {
  margin-top: 50px; }

.BaseContainer_BaseContainer__hl4hw {
  margin-top: 0;
  padding: 0;

}

.BaseContainer_BaseContainerCol__csA3k{
  /* justify-content: space-between; */
}
@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Template_colHeader__3oQ5O, .Template_colHeaderRight__2j6kq {
  font-size: 18px;
  font-weight: 600; }

.Template_colHeaderRight__2j6kq {
  text-align: right;
  padding-right: 25px; }
  .Template_colHeaderRight__2j6kq .Template_linkHeadStyle__3eYJz {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Template_mainBlueButton__1K5Mn, button.Template_mainBlueButtonWithAlignRight__aLrz1 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Template_mainBlueButton__1K5Mn:hover, button.Template_mainBlueButtonWithAlignRight__aLrz1:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Template_mainBlueButtonWithAlignRight__aLrz1 {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Template_mainBlueButtonWithTop__2Eb2H {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Template_mainBlueButtonWithTop__2Eb2H:hover {
    background: #169bd5;
    opacity: 0.7; }

.Template_btnT20__3Z85w {
  margin-top: 20px !important; }

.Template_alignRight__3fgyF {
  text-align: right; }

button.Template_myDefaultIcon__2nJHj {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Template_myDefaultIcon__2nJHj:hover {
    background: #616161; }

button.Template_myRedIcon__1d0ZW {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Template_myRedIcon__1d0ZW:hover {
    background: #990000;
    opacity: 0.7; }

button.Template_myRedButton__3daFt {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Template_myRedButton__3daFt:hover {
    background: #990000;
    opacity: 0.7; }

.Template_cardHeader__1ccss {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Template_cardHeader__1ccss a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Template_cardBody__1L9gw, .Template_cardBodyWithMyStyle__14AzQ {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Template_cardBodyWithMyStyle__14AzQ {
    margin-top: 20px; }

.Template_Image__3vKIO {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Template_Icon__2k2LZ {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Template_selectLink__3Qs8o {
  color: #2b75bb; }
  .Template_selectLink__3Qs8o:hover {
    cursor: pointer; }

.Template_inputstyle__icGnT {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.TemplateHeader_colHeader__2C60Z, .TemplateHeader_colHeaderRight__2w42y {
  font-size: 18px;
  font-weight: 600; }

.TemplateHeader_colHeaderRight__2w42y {
  text-align: right;
  padding-right: 25px; }
  .TemplateHeader_colHeaderRight__2w42y .TemplateHeader_linkHeadStyle__2N2Ux {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.TemplateHeader_mainBlueButton__1ePN3, button.TemplateHeader_mainBlueButtonWithAlignRight__2kC7Q {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.TemplateHeader_mainBlueButton__1ePN3:hover, button.TemplateHeader_mainBlueButtonWithAlignRight__2kC7Q:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.TemplateHeader_mainBlueButtonWithAlignRight__2kC7Q {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.TemplateHeader_mainBlueButtonWithTop__kiC0E {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.TemplateHeader_mainBlueButtonWithTop__kiC0E:hover {
    background: #169bd5;
    opacity: 0.7; }

.TemplateHeader_btnT20__33Zit {
  margin-top: 20px !important; }

.TemplateHeader_alignRight__3OnSS {
  text-align: right; }

button.TemplateHeader_myDefaultIcon__1BGf8 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.TemplateHeader_myDefaultIcon__1BGf8:hover {
    background: #616161; }

button.TemplateHeader_myRedIcon__1StcC {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.TemplateHeader_myRedIcon__1StcC:hover {
    background: #990000;
    opacity: 0.7; }

button.TemplateHeader_myRedButton__1SMUU {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.TemplateHeader_myRedButton__1SMUU:hover {
    background: #990000;
    opacity: 0.7; }

.TemplateHeader_cardHeader__eY-7B {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .TemplateHeader_cardHeader__eY-7B a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.TemplateHeader_cardBody__3rLtB, .TemplateHeader_cardBodyWithMyStyle__Ue4Dv {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .TemplateHeader_cardBodyWithMyStyle__Ue4Dv {
    margin-top: 20px; }

.TemplateHeader_Image__2J8Qr {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.TemplateHeader_Icon__1bddc {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.TemplateHeader_selectLink__3Qom6 {
  color: #2b75bb; }
  .TemplateHeader_selectLink__3Qom6:hover {
    cursor: pointer; }

.TemplateHeader_inputstyle__22T1L {
  font-size: 14px; }

/*.Header {
  padding: 0.5rem;
 background-color: gray;
  width: 100%;
  height: 5rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
 background: linear-gradient(lightgreen, green); 
  color: whitesmoke;
  display: flex;
  align-content: center;
  font-size: 1.5rem;
  justify-content: space-between;
 box-shadow: 1px 1px 5px 2px grey; 
}

.HeaderItem {
  display: inline-block;
}

.Close,
.Close:link,
.Close:visited,
.Close:hover,
.Close:active {
  position: relative;
  top: -20%;
  right: 2%; 
  font-size: 2rem;
  text-decoration: none;
   color: whitesmoke; 
}*/
.TemplateHeader_myTableHeadStyle__vuQEm {
  background: #e6e6e6; }

.TemplateHeader_tableHoverStyle__1QjYb {
  background-color: inherit; }
  .TemplateHeader_tableHoverStyle__1QjYb:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.TemplateHeader_paddingStyle__1h2E1 {
  padding: 10px !important; }

/*.Column {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}

.LeftContainer,
.RightContainer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.RightContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}*/

button.TemplateEdit_myBlueButton__3HDd8 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
}
  button.TemplateEdit_myBlueButton__3HDd8:hover {
      background: #2b75bb;
      opacity: 0.7;
  }

  button.TemplateEdit_myRedButton__3rPEr {
    background: #990000;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 14px;
    margin-left: 6px;
    font-family: 'Source Sans Pro';
    font-weight: 500;
  }
    button.TemplateEdit_myRedButton__3rPEr:hover {
        background: #6a0000;
        opacity: 0.7;
    }

  .TemplateEdit_marginBottom__jj8hJ{
    margin-bottom: 15px;
  }

  .TemplateEdit_fontWeightBold__3-p8M{
    font-weight: 600;
  }

  .TemplateEdit_myStickyTop__2XFVK{
    text-align:right;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 20px 30px;
    background: #fafafa;
    z-index: 10;
}

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.AddHoldingForm_colHeader__2PA_p, .AddHoldingForm_colHeaderRight__21nSo {
  font-size: 18px;
  font-weight: 600; }

.AddHoldingForm_colHeaderRight__21nSo {
  text-align: right;
  padding-right: 25px; }
  .AddHoldingForm_colHeaderRight__21nSo .AddHoldingForm_linkHeadStyle__2mdXQ {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.AddHoldingForm_mainBlueButton__1w8db, button.AddHoldingForm_mainBlueButtonWithAlignRight__1ziZB {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.AddHoldingForm_mainBlueButton__1w8db:hover, button.AddHoldingForm_mainBlueButtonWithAlignRight__1ziZB:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.AddHoldingForm_mainBlueButtonWithAlignRight__1ziZB {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.AddHoldingForm_mainBlueButtonWithTop__1nFzt {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.AddHoldingForm_mainBlueButtonWithTop__1nFzt:hover {
    background: #169bd5;
    opacity: 0.7; }

.AddHoldingForm_btnT20__4g7Bn {
  margin-top: 20px !important; }

.AddHoldingForm_alignRight__bWBDG {
  text-align: right; }

button.AddHoldingForm_myDefaultIcon__35aFB {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddHoldingForm_myDefaultIcon__35aFB:hover {
    background: #616161; }

button.AddHoldingForm_myRedIcon__1TB9H {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.AddHoldingForm_myRedIcon__1TB9H:hover {
    background: #990000;
    opacity: 0.7; }

button.AddHoldingForm_myRedButton__2y88n {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.AddHoldingForm_myRedButton__2y88n:hover {
    background: #990000;
    opacity: 0.7; }

.AddHoldingForm_cardHeader__qqCBq {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .AddHoldingForm_cardHeader__qqCBq a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.AddHoldingForm_cardBody__15QkS, .AddHoldingForm_cardBodyWithMyStyle__181Oq {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .AddHoldingForm_cardBodyWithMyStyle__181Oq {
    margin-top: 20px; }

.AddHoldingForm_Image__2cSrT {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.AddHoldingForm_Icon__1wkwh {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.AddHoldingForm_selectLink__ExDgh {
  color: #2b75bb; }
  .AddHoldingForm_selectLink__ExDgh:hover {
    cursor: pointer; }

.AddHoldingForm_inputstyle__3nOky {
  font-size: 14px; }

.AddHoldingForm_alignRight__bWBDG {
  text-align: right; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Header_colHeader__JuBXh, .Header_colHeaderRight__1W7ty {
  font-size: 18px;
  font-weight: 600; }

.Header_colHeaderRight__1W7ty {
  text-align: right;
  padding-right: 25px; }
  .Header_colHeaderRight__1W7ty .Header_linkHeadStyle__2xYOP {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Header_mainBlueButton__hUmUx, button.Header_mainBlueButtonWithAlignRight__1MPHC {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Header_mainBlueButton__hUmUx:hover, button.Header_mainBlueButtonWithAlignRight__1MPHC:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Header_mainBlueButtonWithAlignRight__1MPHC {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Header_mainBlueButtonWithTop__3UeDT {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Header_mainBlueButtonWithTop__3UeDT:hover {
    background: #169bd5;
    opacity: 0.7; }

.Header_btnT20__3UcF9 {
  margin-top: 20px !important; }

.Header_alignRight__2d1zQ {
  text-align: right; }

button.Header_myDefaultIcon__ppQtc {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Header_myDefaultIcon__ppQtc:hover {
    background: #616161; }

button.Header_myRedIcon__uX0Zy {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Header_myRedIcon__uX0Zy:hover {
    background: #990000;
    opacity: 0.7; }

button.Header_myRedButton__1kl_h {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Header_myRedButton__1kl_h:hover {
    background: #990000;
    opacity: 0.7; }

.Header_cardHeader__1183_ {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Header_cardHeader__1183_ a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Header_cardBody__2r7IJ, .Header_cardBodyWithMyStyle__3tBCa {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Header_cardBodyWithMyStyle__3tBCa {
    margin-top: 20px; }

.Header_Image__15UUG {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Header_Icon__2AOPw {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Header_selectLink__3ENSW {
  color: #2b75bb; }
  .Header_selectLink__3ENSW:hover {
    cursor: pointer; }

.Header_inputstyle__2Zj8e {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Mainpage_colHeader__14jD6, .Mainpage_colHeaderRight__bx6v9 {
  font-size: 18px;
  font-weight: 600; }

.Mainpage_colHeaderRight__bx6v9 {
  text-align: right;
  padding-right: 25px; }
  .Mainpage_colHeaderRight__bx6v9 .Mainpage_linkHeadStyle__3GpTt {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Mainpage_mainBlueButton__2fXr-, button.Mainpage_mainBlueButtonWithAlignRight__enDrv {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Mainpage_mainBlueButton__2fXr-:hover, button.Mainpage_mainBlueButtonWithAlignRight__enDrv:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Mainpage_mainBlueButtonWithAlignRight__enDrv {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Mainpage_mainBlueButtonWithTop__2HvfL {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Mainpage_mainBlueButtonWithTop__2HvfL:hover {
    background: #169bd5;
    opacity: 0.7; }

.Mainpage_btnT20__2uUQm {
  margin-top: 20px !important; }

.Mainpage_alignRight__uqeGE {
  text-align: right; }

button.Mainpage_myDefaultIcon__3PIHy {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Mainpage_myDefaultIcon__3PIHy:hover {
    background: #616161; }

button.Mainpage_myRedIcon__2fljy {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Mainpage_myRedIcon__2fljy:hover {
    background: #990000;
    opacity: 0.7; }

button.Mainpage_myRedButton__3IcHS {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Mainpage_myRedButton__3IcHS:hover {
    background: #990000;
    opacity: 0.7; }

.Mainpage_cardHeader__Bg1wO {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Mainpage_cardHeader__Bg1wO a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Mainpage_cardBody__3W0Fh, .Mainpage_cardBodyWithMyStyle__1Ktxk {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Mainpage_cardBodyWithMyStyle__1Ktxk {
    margin-top: 20px; }

.Mainpage_Image__3LEi5 {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Mainpage_Icon__3qYDF {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Mainpage_selectLink__16uTk {
  color: #2b75bb; }
  .Mainpage_selectLink__16uTk:hover {
    cursor: pointer; }

.Mainpage_inputstyle__ftFil {
  font-size: 14px; }

.Mainpage_myIconStyle__3xdrz {
  cursor: pointer;
  width: 2.5rem; }

.Mainpage_tableHoverStyle__2HqaQ {
  background: inherit; }
  .Mainpage_tableHoverStyle__2HqaQ :hover {
    background: rgba(37, 118, 195, 0.1); }

.Mainpage_myTableHeadStyle__3beG3 {
  background: #e6e6e6; }

.Mainpage_fontStyle__5oFuG {
  color: black !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Source Sans Pro" !important;
  padding: 8px !important; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.RecordConnections_colHeader__HQeLx, .RecordConnections_colHeaderRight__2-rkW {
  font-size: 18px;
  font-weight: 600; }

.RecordConnections_colHeaderRight__2-rkW {
  text-align: right;
  padding-right: 25px; }
  .RecordConnections_colHeaderRight__2-rkW .RecordConnections_linkHeadStyle__16w_J {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.RecordConnections_mainBlueButton__2ZH21, button.RecordConnections_mainBlueButtonWithAlignRight__3-sQe {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.RecordConnections_mainBlueButton__2ZH21:hover, button.RecordConnections_mainBlueButtonWithAlignRight__3-sQe:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.RecordConnections_mainBlueButtonWithAlignRight__3-sQe {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.RecordConnections_mainBlueButtonWithTop__2qx1g {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.RecordConnections_mainBlueButtonWithTop__2qx1g:hover {
    background: #169bd5;
    opacity: 0.7; }

.RecordConnections_btnT20__1Uqvf {
  margin-top: 20px !important; }

.RecordConnections_alignRight__3F2hD {
  text-align: right; }

button.RecordConnections_myDefaultIcon__35gjq {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.RecordConnections_myDefaultIcon__35gjq:hover {
    background: #616161; }

button.RecordConnections_myRedIcon__UMhGK {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.RecordConnections_myRedIcon__UMhGK:hover {
    background: #990000;
    opacity: 0.7; }

button.RecordConnections_myRedButton__3C0G6 {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.RecordConnections_myRedButton__3C0G6:hover {
    background: #990000;
    opacity: 0.7; }

.RecordConnections_cardHeader__zFrdA {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .RecordConnections_cardHeader__zFrdA a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.RecordConnections_cardBody__1XC9_, .RecordConnections_cardBodyWithMyStyle__1TM4T {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .RecordConnections_cardBodyWithMyStyle__1TM4T {
    margin-top: 20px; }

.RecordConnections_Image__3Pn_f {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.RecordConnections_Icon__2flR1 {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.RecordConnections_selectLink__gdhhD {
  color: #2b75bb; }
  .RecordConnections_selectLink__gdhhD:hover {
    cursor: pointer; }

.RecordConnections_inputstyle__2eAM7 {
  font-size: 14px; }

.RecordConnections_RecordConnection__lbOYw {
  color: #2b75bb; }
  .RecordConnections_RecordConnection__lbOYw:hover {
    cursor: pointer; }

.RecordConnections_Tag__2rBB0 {
  font-weight: bold; }

.RecordConnections_Code__tHKUL {
  margin-left: 1rem; }

.RecordConnections_Value__3bY8J {
  margin-left: 1rem; }

.RecordConnections_paddingStyle__3NxWU {
  padding: 10px !important; }

.RecordConnections_tableHoverStyle__a70HD {
  background-color: #ffffff; }
  .RecordConnections_tableHoverStyle__a70HD:hover {
    background-color: rgba(37, 118, 195, 0.1); }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.colHeader, .colHeaderRight {
  font-size: 18px;
  font-weight: 600; }

.colHeaderRight {
  text-align: right;
  padding-right: 25px; }
  .colHeaderRight .linkHeadStyle {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.mainBlueButton, button.mainBlueButtonWithAlignRight {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.mainBlueButton:hover, button.mainBlueButtonWithAlignRight:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.mainBlueButtonWithAlignRight {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.mainBlueButtonWithTop {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.mainBlueButtonWithTop:hover {
    background: #169bd5;
    opacity: 0.7; }

.btnT20 {
  margin-top: 20px !important; }

.alignRight {
  text-align: right; }

button.myDefaultIcon {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.myDefaultIcon:hover {
    background: #616161; }

button.myRedIcon {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.myRedIcon:hover {
    background: #990000;
    opacity: 0.7; }

button.myRedButton {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.myRedButton:hover {
    background: #990000;
    opacity: 0.7; }

.cardHeader {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .cardHeader a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.cardBody, .cardBodyWithMyStyle {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .cardBodyWithMyStyle {
    margin-top: 20px; }

.Image {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Icon {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.selectLink {
  color: #2b75bb; }
  .selectLink:hover {
    cursor: pointer; }

.inputstyle {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Header_colHeader__28nO5, .Header_colHeaderRight__kUKXJ {
  font-size: 18px;
  font-weight: 600; }

.Header_colHeaderRight__kUKXJ {
  text-align: right;
  padding-right: 25px; }
  .Header_colHeaderRight__kUKXJ .Header_linkHeadStyle__3nz1c {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Header_mainBlueButton__2MUya, button.Header_mainBlueButtonWithAlignRight__2fV-V {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Header_mainBlueButton__2MUya:hover, button.Header_mainBlueButtonWithAlignRight__2fV-V:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Header_mainBlueButtonWithAlignRight__2fV-V {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Header_mainBlueButtonWithTop__1_wEh {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Header_mainBlueButtonWithTop__1_wEh:hover {
    background: #169bd5;
    opacity: 0.7; }

.Header_btnT20__5kaeq {
  margin-top: 20px !important; }

.Header_alignRight__24ycz {
  text-align: right; }

button.Header_myDefaultIcon__3qWGp {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Header_myDefaultIcon__3qWGp:hover {
    background: #616161; }

button.Header_myRedIcon__XhvYi {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Header_myRedIcon__XhvYi:hover {
    background: #990000;
    opacity: 0.7; }

button.Header_myRedButton__1YrEg {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Header_myRedButton__1YrEg:hover {
    background: #990000;
    opacity: 0.7; }

.Header_cardHeader__1YpO9 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Header_cardHeader__1YpO9 a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Header_cardBody__AsdTU, .Header_cardBodyWithMyStyle__BAySD {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Header_cardBodyWithMyStyle__BAySD {
    margin-top: 20px; }

.Header_Image__4v-8W {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Header_Icon__3paWL {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Header_selectLink__1Feh5 {
  color: #2b75bb; }
  .Header_selectLink__1Feh5:hover {
    cursor: pointer; }

.Header_inputstyle__1fp27 {
  font-size: 14px; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.List_colHeader__1ZTU3, .List_colHeaderRight__337LQ {
  font-size: 18px;
  font-weight: 600; }

.List_colHeaderRight__337LQ {
  text-align: right;
  padding-right: 25px; }
  .List_colHeaderRight__337LQ .List_linkHeadStyle__3o4Jp {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.List_mainBlueButton__3QX2G, button.List_mainBlueButtonWithAlignRight__7T5TC {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.List_mainBlueButton__3QX2G:hover, button.List_mainBlueButtonWithAlignRight__7T5TC:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.List_mainBlueButtonWithAlignRight__7T5TC {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.List_mainBlueButtonWithTop__1LXhS {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.List_mainBlueButtonWithTop__1LXhS:hover {
    background: #169bd5;
    opacity: 0.7; }

.List_btnT20__2nmhn {
  margin-top: 20px !important; }

.List_alignRight__3jyIY {
  text-align: right; }

button.List_myDefaultIcon__3BgLK {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myDefaultIcon__3BgLK:hover {
    background: #616161; }

button.List_myRedIcon__2i-6Y {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myRedIcon__2i-6Y:hover {
    background: #990000;
    opacity: 0.7; }

button.List_myRedButton__38IpE {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.List_myRedButton__38IpE:hover {
    background: #990000;
    opacity: 0.7; }

.List_cardHeader__25vBp {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .List_cardHeader__25vBp a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.List_cardBody__1G8AN, .List_cardBodyWithMyStyle__3tolT {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .List_cardBodyWithMyStyle__3tolT {
    margin-top: 20px; }

.List_Image__2OmVz {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.List_Icon__1tpKS {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.List_selectLink__2ySSW {
  color: #2b75bb; }
  .List_selectLink__2ySSW:hover {
    cursor: pointer; }

.List_inputstyle__1toTn {
  font-size: 14px; }

.List_myTableHeadStyle__34JdE {
  background: #e6e6e6; }

.List_tableHoverStyle__2J5S3 {
  background-color: inherit; }
  .List_tableHoverStyle__2J5S3:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.List_paddingStyle__GP0Tn {
  padding: 10px !important; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.Reader_colHeader__bQfTl, .Reader_colHeaderRight__2JXRp {
  font-size: 18px;
  font-weight: 600; }

.Reader_colHeaderRight__2JXRp {
  text-align: right;
  padding-right: 25px; }
  .Reader_colHeaderRight__2JXRp .Reader_linkHeadStyle__2rRjU {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.Reader_mainBlueButton__2Qai3, button.Reader_mainBlueButtonWithAlignRight__18Uf9 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.Reader_mainBlueButton__2Qai3:hover, button.Reader_mainBlueButtonWithAlignRight__18Uf9:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.Reader_mainBlueButtonWithAlignRight__18Uf9 {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.Reader_mainBlueButtonWithTop__1xFp2 {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.Reader_mainBlueButtonWithTop__1xFp2:hover {
    background: #169bd5;
    opacity: 0.7; }

.Reader_btnT20__2sJup {
  margin-top: 20px !important; }

.Reader_alignRight__xV9v7 {
  text-align: right; }

button.Reader_myDefaultIcon__1u1Z4 {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Reader_myDefaultIcon__1u1Z4:hover {
    background: #616161; }

button.Reader_myRedIcon__1mZKh {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.Reader_myRedIcon__1mZKh:hover {
    background: #990000;
    opacity: 0.7; }

button.Reader_myRedButton__1XgKr {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.Reader_myRedButton__1XgKr:hover {
    background: #990000;
    opacity: 0.7; }

.Reader_cardHeader__2E49P {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .Reader_cardHeader__2E49P a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.Reader_cardBody__2HJtb, .Reader_cardBodyWithMyStyle__33VNH {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .Reader_cardBodyWithMyStyle__33VNH {
    margin-top: 20px; }

.Reader_Image__Obozz {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.Reader_Icon__1FHeP {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.Reader_selectLink__1ICwI {
  color: #2b75bb; }
  .Reader_selectLink__1ICwI:hover {
    cursor: pointer; }

.Reader_inputstyle__2nnNH {
  font-size: 14px; }

.Reader_myIconStyle__1SK6E {
  cursor: pointer;
  width: 2.5rem; }

.Reader_tableHoverStyle__3Y2Va {
  background: inherit; }
  .Reader_tableHoverStyle__3Y2Va :hover {
    background: rgba(37, 118, 195, 0.1); }

.Reader_myTableHeadStyle__3_4F_ {
  background: #e6e6e6; }

.Reader_fontStyle__1gxKP {
  color: black !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Source Sans Pro" !important;
  padding: 8px !important; }

@charset "UTF-8";
/* Színek */
/* MIXIN'S */
/* BASIC STYLES */
.List_colHeader__3moGu, .List_colHeaderRight__dKKpC {
  font-size: 18px;
  font-weight: 600; }

.List_colHeaderRight__dKKpC {
  text-align: right;
  padding-right: 25px; }
  .List_colHeaderRight__dKKpC .List_linkHeadStyle__1AU-Q {
    font-weight: 600;
    font-size: 20px;
    color: #2b75bb;
    text-decoration: none; }

button.List_mainBlueButton__3JUTu, button.List_mainBlueButtonWithAlignRight__1LmQs {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  text-decoration: none; }
  button.List_mainBlueButton__3JUTu:hover, button.List_mainBlueButtonWithAlignRight__1LmQs:hover {
    background: #2b75bb;
    opacity: 0.7; }
  button.List_mainBlueButtonWithAlignRight__1LmQs {
    position: absolute;
    bottom: 20px;
    right: 50px; }

button.List_mainBlueButtonWithTop__3zEPp {
  background: #2b75bb;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  margin-right: 6px;
  z-index: inherit;
  margin-top: 50px; }
  button.List_mainBlueButtonWithTop__3zEPp:hover {
    background: #169bd5;
    opacity: 0.7; }

.List_btnT20__3-Ql- {
  margin-top: 20px !important; }

.List_alignRight__2s0i7 {
  text-align: right; }

button.List_myDefaultIcon__gjADz {
  background: #616161;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myDefaultIcon__gjADz:hover {
    background: #616161; }

button.List_myRedIcon__1fmNI {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500;
  min-width: 1px; }
  button.List_myRedIcon__1fmNI:hover {
    background: #990000;
    opacity: 0.7; }

button.List_myRedButton__G1zRI {
  background: #990000;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2px 14px;
  margin-left: 6px;
  font-family: 'Source Sans Pro';
  font-weight: 500; }
  button.List_myRedButton__G1zRI:hover {
    background: #990000;
    opacity: 0.7; }

.List_cardHeader__3QjTs {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 20px 0;
  text-align: center;
  border-bottom: 1px solid #c6c6c6; }
  .List_cardHeader__3QjTs a {
    font-weight: 400;
    font-size: 16px;
    color: #2b75bb;
    text-decoration: none; }

.List_cardBody__xstSV, .List_cardBodyWithMyStyle__3QdnJ {
  padding-right: 50px;
  padding-left: 50px;
  z-index: 1;
  padding: 0 50px;
  padding-top: 20px; }
  .List_cardBodyWithMyStyle__3QdnJ {
    margin-top: 20px; }

.List_Image__3Es8D {
  position: relative;
  max-height: 25px;
  margin-right: .25rem;
  border-radius: 6px; }

img:hover {
  opacity: 0.7; }

.List_Icon__3JKqW {
  position: relative;
  max-height: 35px;
  margin-right: .25rem;
  margin-left: 10px; }

.List_selectLink__1gKrv {
  color: #2b75bb; }
  .List_selectLink__1gKrv:hover {
    cursor: pointer; }

.List_inputstyle__EMzQw {
  font-size: 14px; }

.List_myTableHeadStyle__3MlzE {
  background: #e6e6e6; }

.List_tableHoverStyle__2kC9x {
  background-color: inherit; }
  .List_tableHoverStyle__2kC9x:hover {
    background-color: rgba(37, 118, 195, 0.1); }

.List_paddingStyle__1CmC7 {
  padding: 10px !important; }

