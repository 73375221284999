@import "../../../css/basic_def.scss";

.Datafields {
  margin-bottom: 10vh;
}

.myFontStyle{
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.myIconStyle{
  cursor: pointer;
  width: 2.1875rem;

}

.subFont {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.aligSelf{
  text-align: right;
}

.inputstyle{
  font-size: 14px;
}

.InvalidValue {
  border: 1px solid red;
}