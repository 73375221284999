@import "../../../css/basic_def.scss";


/*.Header {
  padding: 0.5rem;
 background-color: gray;
  width: 100%;
  height: 5rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
 background: linear-gradient(lightgreen, green); 
  color: whitesmoke;
  display: flex;
  align-content: center;
  font-size: 1.5rem;
  justify-content: space-between;
 box-shadow: 1px 1px 5px 2px grey; 
}

.HeaderItem {
  display: inline-block;
}

.Close,
.Close:link,
.Close:visited,
.Close:hover,
.Close:active {
  position: relative;
  top: -20%;
  right: 2%; 
  font-size: 2rem;
  text-decoration: none;
   color: whitesmoke; 
}*/

.myTableHeadStyle {
  background: #e6e6e6
}

.tableHoverStyle {
  background-color: inherit;
  &:hover {
      background-color: rgba(37, 118, 195, 0.1);
  }
}

.paddingStyle {
  padding: 10px !important;
}