@import "../../../../../css/basic_def.scss";

.RecordConnection {
  color: #2b75bb;
    &:hover {
        cursor: pointer;
    }
}

.Tag {
  font-weight: bold;
}

.Code {
  margin-left: 1rem;
}

.Value {
  margin-left: 1rem; 
}

.paddingStyle {
  padding: 10px !important;
}

.tableHoverStyle {
  background-color: #ffffff;
   
    &:hover {
        background-color: rgba(37, 118, 195, 0.1);
    }
}