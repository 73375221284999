@import "../../../css/basic_def.scss";

.myIconStyle{
    cursor: pointer;
    width: 2.5rem;
}

.tableHoverStyle {
    background: inherit;
    :hover {
        background: rgba(37, 118, 195, 0.1);
    }
}

.myTableHeadStyle {
    background: #e6e6e6;
}

.fontStyle{
    color: black !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: 'Source Sans Pro'!important;
    padding: 8px !important;
}